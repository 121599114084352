import * as React from "react";
import { AppCard } from "./pages/appList-components";

interface dataProps {
  fullName: string;
  statusChange: string;
  sourceRate: string;
  rate: string;
  inputCount: string;
  outputCount: string;
}

interface AppsListProps {
  data: dataProps[] | { message: string };
}

const AppsList: React.FC<AppsListProps> = ({ data }) => {
  return (
    <div>
      {Array.isArray(data) ? (
        data?.map((item: dataProps, index: number) => (
          <AppCard item={item} key={index} />
        ))
      ) : (
        <>{data?.message}</>
      )}
    </div>
  );
};

export default AppsList;
export { dataProps };
