const callApi = async (tungstenQuery, retryCount = 5, retryDelay = 1000) => {
  const url = localStorage.getItem("apiUrl");
  const token = localStorage.getItem("apiToken");

  for (let attempt = 1; attempt <= retryCount; attempt++) {
    try {
      const res = await fetch("/api", {
        method: "POST",
        body: JSON.stringify({
          url: url,
          token: token,
          tungstenQuery: tungstenQuery,
        }),
      });
      const data = await res.json();
      if (data.executionStatus === "Success") {
        return data.output;
      }
    } catch (err) {
      console.log(`Attempt ${attempt} failed:`, tungstenQuery, err);
      if (attempt === retryCount) {
        console.error("All retry attempts failed.")
      }
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    }
  }
};

const getHealth = async (retryCount = 5, retryDelay = 1000) => {
  const url = localStorage.getItem("apiUrl");
  const token = localStorage.getItem("apiToken");

  for (let attempt = 1; attempt <= retryCount; attempt++) {
    try {
      const res = await fetch("/health", {
        method: "POST",
        body: JSON.stringify({
          url: url,
          token: token,
        }),
      });
      const out = await res.json();
      if (out.reason === "tkn") {
        throw new Error('All retry attempts failed due to "tkn" response.');
      } 
      return out;
    } catch (err) {
      console.log(`Attempt ${attempt} failed: health`, err);
      if (attempt === retryCount) {
        console.error("All retry attempts failed.")
      }
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    }
  }
};

const checkApiToken = async (apiUrl: string, apiToken: string): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `STRIIM-TOKEN ${apiToken}`);
      myHeaders.append("Content-Type", "text/plain");

      const requestURL = new URL(apiUrl + "/api/v2/apitoken");

      const res = await fetch(requestURL.href, {
        method: "GET",
        headers: myHeaders,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });

const runTungstenQuery = async (tungstenQuery: string): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      const apiToken = localStorage.getItem("apiToken");
      const apiUrl = localStorage.getItem("apiUrl");
      const res = await fetch("/api", {
        method: "POST",
        body: JSON.stringify({
          url: apiUrl,
          token: apiToken,
          tungstenQuery: tungstenQuery,
        }),
      });
      const data = await res.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
export { runTungstenQuery, checkApiToken, callApi, getHealth };
