import { bootstrap } from "@ms-fabric/workload-client";

console.log("****Runtime: Environment Variables****");
console.log("process.env.WORKLOAD_NAME: " + process.env.WORKLOAD_NAME);
console.log("**************************************");

import { renderLocalDevApp } from "./index.ui";

if (process.env.MODE === "LOCAL") {
  renderLocalDevApp();
} else {
  bootstrap({
    initializeWorker: (params) =>
      import("./index.worker").then(({ initialize }) => initialize(params)),
    initializeUI: (params) =>
      import("./index.ui").then(({ initialize }) => initialize(params)),
  });
}
