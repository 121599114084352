import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import AppsList, { type dataProps } from "../appsList";

import dayjs from "dayjs";
import { styles } from "./appsList.styles";
import {
  APPS_DETAIL_PREFIX,
  AppsTitle,
  HEALTH_DATA,
  LoadingComponent,
  LogoutButton,
} from "./appList-components";
import { callApi, getHealth } from "../../controller/controller";

const POLLING_INTERVAL = 30_000;
interface AppsListPageProps {
  setLoggedIn: React.Dispatch<SetStateAction<boolean>>;
}
const AppsListPage: React.FC<AppsListPageProps> = ({ setLoggedIn }) => {
  const [appDetails, setAppDetails] = useState<dataProps[]>();
  const [refreshedTime, setRefreshedTime] = useState<string>();

  const fetchAppsData = async (cb=null): Promise<void> => {
    console.log("fetchAppsData called")
    const monData = await callApi("mon;");
    const healthData = await getHealth();

    if (!monData || !healthData?.healthRecords) return;
    setRefreshedTime(dayjs().format("hh:mm:ss A"));
    setAppDetails(monData.striimApplications);
    if (cb) {
      sessionStorage.setItem(HEALTH_DATA,JSON.stringify(healthData.healthRecords?.[0]));
      cb(monData.striimApplications);
    }
  };


  let doCall = async function(appDetails){
    console.log("callback called")
    for(let i = 0; i< appDetails?.length; i++){
      const appName = appDetails[i].fullName;
      let app = {
        applicationComponents: undefined,
        comps: undefined
      }
      if(sessionStorage.getItem(APPS_DETAIL_PREFIX+appName)){
        app = JSON.parse(sessionStorage.getItem(APPS_DETAIL_PREFIX+appName));
      }
      else{
        app = await callApi(`mon ${appName};`);
        let appComps = [];
        if(!app?.applicationComponents) {
          sessionStorage.setItem(APPS_DETAIL_PREFIX + appName, JSON.stringify({ noApps:true }));
          continue;
        }
        appComps = appComps.concat(app.applicationComponents);
        app.applicationComponents?.forEach((comp)=>{
          if(comp.flowComponents){
            appComps = appComps.concat(comp.flowComponents);
          }
        })
        app.comps = appComps;
        for(let j = 0; j < app.comps.length; j++){
          let comp = app.comps[j];
          if(comp.entityType === "TARGET" || comp.entityType === "SOURCE"){
            let desc = await callApi(`describe ${comp.fullName};`);
            await delay(100);
            sessionStorage.setItem(APPS_DETAIL_PREFIX+comp.fullName, JSON.stringify(desc[0]));
          }
        }
        sessionStorage.setItem(APPS_DETAIL_PREFIX+appName, JSON.stringify(app));
      }
      await delay(100);

    }
  }


  useEffect(() => {
    // for fetching mon
    const monInterval = setInterval(() => {
      fetchAppsData(doCall);
    }, POLLING_INTERVAL);
    fetchAppsData(doCall);


    return () => {
      clearInterval(monInterval);
    };
  }, []);
  const delay = (time) => {
    return new Promise(res => {
      setTimeout(res,time)
    })
  };

  const logout = () => {
    setLoggedIn(false);
    sessionStorage.clear();
    localStorage.clear();
  };

  const MemoisedAppsList = useMemo(
    () => <AppsList data={appDetails} />,
    [appDetails]
  );

  return (
    <>
      {/* @ts-ignore */}
      {!appDetails ? (
        <LoadingComponent />
      ) : (
        <div style={styles.container}>
          <LogoutButton onLogout={() => logout()} />
          <AppsTitle refreshedTime={refreshedTime} />
          {MemoisedAppsList}
        </div>
      )}
    </>
  );
};
export default AppsListPage;
