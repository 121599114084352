import React, { useEffect, useState } from "react";
import {
  makeStyles,
  useId,
  Input,
  Label,
  Button,
  Toaster,
  Toast,
  ToastTitle,
  useToastController,
  typographyStyles,
} from "@fluentui/react-components";
import AppsListPage from "./appsListPage";
import Logo from "../Logo";
import { callDialogOpenMsgBox } from "../../controller/workloadController";
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    maxWidth: "400px",
    margin: "30% auto",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    height: "95vh",
  },
  imageContainer: {
    flex: 1,
    borderLeft: "1px solid #BCC9D6",
    backgroundColor: "#F7FBFF",
  },
  title: typographyStyles.title2,
});

const Login = ({ workloadClient }) => {
  const inputId = useId("input");
  const styles = useStyles();

  const [apiUrl, setApiUrl] = useState(null);
  const [apiToken, setApiToken] = useState(null);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [loggedIn, setLoggedIn] = useState<boolean>(
    (() => {
      try {
        const isLoggedin = JSON.parse(localStorage.getItem("isLoggedin"));
        return isLoggedin;
      } catch (e) {
        localStorage.clear();
        return false;
      }
    })()
  );

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const handleLogin = async (url: string, token: string) => {
    try {
      const tungstenQuery = "mon;";

      const res = await fetch("/api", {
        method: "POST",
        body: JSON.stringify({
          url: url,
          token: token,
          tungstenQuery: tungstenQuery,
        }),
      });

      const data = await res.json();
      if (data.executionStatus === "Success") {
        setLoggedIn(true);
        localStorage.setItem("apiUrl", url);
        localStorage.setItem("apiToken", token);
        localStorage.setItem("isLoggedin", JSON.stringify(true));
      } else {
        throw new Error("Could not authenticate user");
      }
    } catch (err) {
      const errorMessage = err?.message ?? JSON.stringify(err);
      dispatchToast(
        <Toast>
          <ToastTitle>{errorMessage}</ToastTitle>
        </Toast>,
        { intent: "error" }
      );
      setLoggedIn(false);
      localStorage.setItem("isLoggedin", JSON.stringify(false));
      localStorage.clear();
      setLoggedIn(false);
    }
  };

  async function openDialog() {
    await callDialogOpenMsgBox(
      "Azure Marketplace",
      "This will take you to the ",
      {
        url: "https://azuremarketplace.microsoft.com/en-us/marketplace/apps/striim.sql2fabric-mirroring",
        label: "Azure Marketplace",
      },
      [],
      workloadClient
    );
  }

  return (
    <>
      {loggedIn ? (
        <AppsListPage setLoggedIn={setLoggedIn} />
      ) : (
        <div className={styles.gridContainer}>
          <div style={{ flex: 1 }}>
            <div className={styles.root}>
              <Toaster toasterId={toasterId} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Logo />{" "}
                <span style={{ color: "#1E1E5C" }} className={styles.title}>
                  Sign in to Striim - Test
                </span>
              </div>
              <div style={{ color: "#6E7D8C", marginBottom: "20px" }}>
                Sign in using the API token acquired from Striim SAAS
              </div>
              <Label
                color="#6E7D8C"
                style={{ color: "#6E7D8C" }}
                htmlFor={inputId}
              >
                Rest API url
              </Label>
              <Input
                id={inputId}
                size="medium"
                onChange={(e) => {
                  setApiUrl(e.target.value);
                }}
                required
              />
              <Label
                color="#6E7D8C"
                style={{ marginTop: "10px", color: "#6E7D8C" }}
                htmlFor={inputId}
              >
                API Token
              </Label>
              <Input
                id={inputId}
                size="medium"
                required
                type="password"
                onChange={(e) => setApiToken(e.target.value)}
              />
              <Button
                style={{ maxWidth: "150px", margin: "30px auto" }}
                appearance="primary"
                onClick={() => handleLogin(apiUrl, apiToken)}
              >
                Login
              </Button>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <div style={{ flex: 1 }}>
              <div className={styles.root}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles.title} style={{ color: "#1E1E5C" }}>
                    New to Striim
                  </div>
                  <div style={{ color: "#6E7D8C" }}>
                    Create an account through azure marketplace
                  </div>
                  <Button
                    icon={
                      <>
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.003 5.5H21a1 1 0 0 1 .993.883L22 6.5v7a1 1 0 0 1-1.994.117L20 13.5l-.001-4.586-7.292 7.293a1 1 0 0 1-1.32.084l-.094-.083-2.296-2.292-5.288 5.29a1 1 0 0 1-1.497-1.32l.083-.095 5.994-5.996a1 1 0 0 1 1.32-.084l.094.083 2.296 2.292L18.584 7.5h-4.581a1 1 0 0 1-.993-.883l-.007-.117a1 1 0 0 1 .883-.993l.117-.007H21h-6.997Z"
                            fill="#ffffff"
                          />
                        </svg>
                      </>
                    }
                    appearance="primary"
                    onClick={openDialog}
                  >
                    Start my 30 days free trial now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
