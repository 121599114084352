import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { History } from "history";

import Login from "./striim/components/pages/login";

import { WorkloadClientAPI } from "@ms-fabric/workload-client";

interface AppProps {
  history: History;
  workloadClient?: WorkloadClientAPI;
}

export interface PageProps {
  workloadClient: WorkloadClientAPI;
}

export interface ContextProps {
  itemObjectId?: string;
  workspaceObjectId?: string;
}

export function App({ history, workloadClient }: AppProps) {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/sample-workload-frontend-only">
          <Login workloadClient={workloadClient} />
        </Route>
        <Route path="/">
          <Login workloadClient={workloadClient} />
        </Route>
      </Switch>
    </Router>
  );
}
