// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 12px;
    height: 12px;
    margin: 4px 20px;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
    position: relative; 
}
  
@keyframes l5 {
    0% { box-shadow: 20px 0 #346CC2, -20px 0 #346CC233; background: #346CC2; }
    33% { box-shadow: 20px 0 #346CC2, -20px 0 #346CC233; background: #346CC233; }
    66% { box-shadow: 20px 0 #346CC233, -20px 0 #346CC2; background: #346CC233; }
    100% { box-shadow: 20px 0 #346CC233, -20px 0 #346CC2; background: #346CC2; }
}
`, "",{"version":3,"sources":["webpack://./src/striim/components/pages/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,KAAK,6CAA6C,EAAE,mBAAmB,EAAE;IACzE,MAAM,6CAA6C,EAAE,qBAAqB,EAAE;IAC5E,MAAM,6CAA6C,EAAE,qBAAqB,EAAE;IAC5E,OAAO,6CAA6C,EAAE,mBAAmB,EAAE;AAC/E","sourcesContent":[".loader {\n    width: 12px;\n    height: 12px;\n    margin: 4px 20px;\n    border-radius: 50%;\n    animation: l5 1s infinite linear alternate;\n    position: relative; \n}\n  \n@keyframes l5 {\n    0% { box-shadow: 20px 0 #346CC2, -20px 0 #346CC233; background: #346CC2; }\n    33% { box-shadow: 20px 0 #346CC2, -20px 0 #346CC233; background: #346CC233; }\n    66% { box-shadow: 20px 0 #346CC233, -20px 0 #346CC2; background: #346CC233; }\n    100% { box-shadow: 20px 0 #346CC233, -20px 0 #346CC2; background: #346CC2; }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
