import React, {useEffect, useRef, useState} from "react";
import { Button } from "@fluentui/react-components";
import numeral from "numeral";

import { styles } from "./appsList.styles";
import { LoadingLogo } from "../Logo";
import dayjs from "dayjs";
import "./loader.css";

export const APPS_DETAIL_PREFIX = "APP_DETAILS::";
export const HEALTH_DATA = "HEALTH_DATA";

export const LoadingComponent = () => {
  return (
    // @ts-ignore
    <div style={styles.loadingContainer}>
      <LoadingLogo />
      <label>Loading data...</label>
    </div>
  );
};

export const LogoutButton = ({ onLogout }) => {
  return (
    <div style={styles.logout}>
      <Button
        appearance="primary"
        onClick={onLogout}
        style={{ marginLeft: "auto" }}
      >
        Logout
      </Button>
    </div>
  );
};

const Loader = () => {

  return (
      <div className="loader"></div>
  );
};

export const AppsTitle = ({ refreshedTime }) => {
  return (
    // @ts-ignore
    <div style={styles.titleContainer}>
      <label style={styles.titleFont}>Your Striim applications</label>
      <label style={styles.timestamp}>Refreshed at {refreshedTime}</label>
    </div>
  );
};

const COLORS = {
  SOURCE: "#346CC2",
  TARGET: "#2E8071",
};

export const StatElement = ({ label, value, type, isLoading = false }) => {
  return (
    // @ts-ignore
    <div style={styles.statContainer}>
      <label style={styles.statTitle}>{label}</label>
      {!isLoading ? (
        <label style={{ fontSize: "24px", color: COLORS[type] }}>{value}</label>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const getLee = (targetHealth) => {
  const LeeData = targetHealth?.lagEnd2End?.data ?? null;
  if(LeeData){
    const maxLee = LeeData.reduce((max, item) => Math.max(max, item.lee), -Infinity);
    return `${maxLee} s`
  }
  return "N/A"
}

const getLastWriteTime = (targetHealth) => {
  const time = targetHealth?.lastWriteTime ?? null
  return time ? dayjs(targetHealth.lastWriteTime).format("MMM DD, YYYY h:mm A") : "N/A"
}

const InformationTab = ({item, targetHealth}) => {
  const LeeData = getLee(targetHealth)
  const LastWriteTime = getLastWriteTime(targetHealth)

  return(
    // @ts-ignore
    <div style={styles.statWrapper}>
      <StatElement
        value={`${item.sourceRate} records/s`}
        label={"Input Rate"}
        type="SOURCE"
      />
      <StatElement
        value={`${item.rate} records/s`}
        label={"Output Rate"}
        type="TARGET"
      />
      <StatElement
        value={LeeData}
        label={"End to End Lag"}
        type="TARGET"
        isLoading={!targetHealth}
      />
      <StatElement
        value={LastWriteTime}
        label={"Last write time"}
        type="SOURCE"
        isLoading={!targetHealth}
      />            
    </div>
  )
}
// Returns MSSQLSource or source[0]
const getPreferredSource = (sourceList) => {
  if(!sourceList.length) return null;
  const MSSQLSource = sourceList.find(source => 
    (source.properties.adaptername === "DatabaseReader" && source.properties.databaseprovidertype === "SQLServer") ||
    source.properties.adaptername === "MSSqlReader"
  );
  return MSSQLSource ?? sourceList[0]
}
// Returns FabricTarget or source[0]
const getPreferredTraget = (targetList) => {
  if(!targetList.length) return null;
  const FabricTarget = targetList.find(target => target.adapterName === "FabricMirrorWriter");
  return FabricTarget ?? targetList[0]
}

export const AppCard = ({ item }) => {

  const [source, setSource] = useState(null);
  const [target, setTarget] = useState(null);
  const [targetHealth, setTargetHealth] = useState(null);
  const intervalRef = useRef(null);

    const doCall = function(){
      console.log("Card ReRendered")
        const appDetails = JSON.parse(sessionStorage.getItem( APPS_DETAIL_PREFIX + item.fullName) || null);
        const healthData = JSON.parse(sessionStorage.getItem(HEALTH_DATA) || null);
        if(!appDetails){
          setTargetHealth(null);
          setSource(null);
          setTarget(null);
        } else if(appDetails?.noApps === true){
          setTargetHealth(appDetails);
          setSource("");
          setTarget("");
        } else {
          const sourceList = [];
          const targetList = [];
          appDetails?.comps?.forEach((comp) => {
              if(comp.entityType === "SOURCE"){
                  const sourceComp = JSON.parse(sessionStorage.getItem( APPS_DETAIL_PREFIX + comp.fullName) || null);
                  sourceList.push(sourceComp);
              }
              if(comp.entityType === "TARGET"){
                  const targetComp = JSON.parse(sessionStorage.getItem( APPS_DETAIL_PREFIX + comp.fullName) || null);
                  targetList.push(targetComp);
              }
          });
          const preferredSource = getPreferredSource(sourceList);
          const preferredTarget = getPreferredTraget(targetList)
          preferredSource && setSource(preferredSource?.properties?.connectionurl)
          
          if(preferredTarget){
            setTarget(preferredTarget?.properties?.mirroreddatabasename)
            if (healthData?.targetHealthMap?.[preferredTarget?.name]) {
              setTargetHealth(healthData?.targetHealthMap?.[preferredTarget?.name]);
            }
          }
        }
    };

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            doCall();
        }, 5000);
        doCall();
        return () => {
            if(intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [item]);

    useEffect(() => {
      if(source !== null && target !== null && targetHealth !== null && intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }, [source, target, targetHealth])
    


        return (
    <div style={styles.cardContainer}>
      <div style={styles.cardTitle}>
        <label>
          <span style={styles.cardData}>App name: </span>
          {item.fullName}
        </label>
        <label>
          <span style={styles.cardData}>Status: </span>
          {item.statusChange}
        </label>
      </div>
      {/* @ts-ignore */}
      <InformationTab item={item} targetHealth={targetHealth}/>
      {/* @ts-ignore */}
      <div style={styles.labelsData}>
        {source && 
          <label>
            <span style={styles.cardData}>Reading from: </span>
            {source}
          </label>
        }
        {target && 
          <label>
            <span style={styles.cardData}>Writing to Mirror Database: </span>
            {target}
          </label>
        }
      </div>
    </div>
  );
};
