export const styles = {
  container: {
    marginTop: "20px",
    padding: "24px",
  },
  titleFont: {
    fontSize: "24px",
    lineHeight: "32px",
  },
  timestamp: {
    color: "#6E7D8C",
    fontSize: "14px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "8px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
  },
  logout: {
    width: "100%",
    display: "flex",
    marginBottom: "8px",
  },
  statWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    justifyContent:'space-between'
  },
  statContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "8px",
    flexBasis: '25%',
  },
  statTitle: {
    color: "#6E7D8C",
    fontSize: "14px",
  },
  cardContainer: {
    border: "1px solid #E1E1E1",
    padding: "8px",
    marginBottom: "16px",
    borderRadius: "12px",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px 8px",
  },
  cardData: {
    color: "#6E7D8C",
    fontSize: "14px",
  },
  labelsData:{
    display: 'flex',
    flexDirection: 'column',
    margin: '8px',
    gap: '4px',
  }
};
